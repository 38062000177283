import axios from 'axios';

const state = {
  treasuryItems: []
}

const mutations = {
  UPDATE_TREASURY_ITEMS (state, payload) {
    state.treasuryItems = payload;
  }
}

const actions = {
  getTreasuryItems ({ commit }) {
    axios.get(`/api/v1/econ/treasuries`).then((response) => {
      commit('UPDATE_TREASURY_ITEMS', response.data)
    });
  }
}

const getters = {
  treasuryItems: state => state.TreasuryItems
}

const treasuryModule = {
  state,
  mutations,
  actions,
  getters
}

export default treasuryModule
