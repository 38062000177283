<template>
  <div class="home-container">
    <header>
      <h1>FundForge LLC</h1>
      <h2>AI-Powered Financial Data Solutions</h2>
    </header>

    <nav>
      <button @click="activeSection = 'about'" :class="{ active: activeSection === 'about' }">About Us</button>
      <button @click="activeSection = 'services'" :class="{ active: activeSection === 'services' }">Services</button>
      <button @click="activeSection = 'contact'" :class="{ active: activeSection === 'contact' }">Contact</button>
      <router-link to="/about" class="about-link">About Ed Powers</router-link>
    </nav>

    <main>
      <section v-if="activeSection === 'about'">
        <h3>About FundForge LLC</h3>
        <p>FundForge LLC is a leading data science and consulting company specializing in AI and machine learning solutions for financial data. With expertise in training large language models (LLMs) for various applications, we help businesses leverage cutting-edge technology to gain valuable insights and make data-driven decisions.</p>
      </section>

      <section v-if="activeSection === 'services'">
        <h3>Our Services</h3>
        <ul>
          <li>AI-powered financial data analysis</li>
          <li>Machine learning model development for finance</li>
          <li>LLM training and implementation</li>
          <li>Custom AI solutions for financial applications</li>
          <li>Data science consulting</li>
        </ul>
      </section>

      <section v-if="activeSection === 'contact'">
        <h3>Contact Us</h3>
        <p>Ready to harness the power of AI for your financial data? Get in touch with our team of experts today.</p>
        <a href="mailto:info@fundforge.com" class="cta-button">Contact FundForge</a>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      activeSection: 'about'
    }
  }
}
</script>

<style scoped>
.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

header {
  text-align: center;
  margin-bottom: 2rem;
}

h1 {
  font-size: 2.5rem;
  color: #333;
}

h2 {
  font-size: 1.5rem;
  color: #666;
}

nav {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

button {
  background-color: #f0f0f0;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

button.active {
  background-color: #007bff;
  color: white;
}

main {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
}

h3 {
  color: #007bff;
  margin-bottom: 1rem;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 0.5rem;
}

.cta-button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  margin-top: 1rem;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .home-container {
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  nav {
    flex-direction: column;
  }

  button {
    margin: 0.25rem 0;
  }
}

.about-link {
  display: inline-block;
  background-color: #f0f0f0;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.about-link:hover {
  background-color: #007bff;
  color: white;
}
</style>
