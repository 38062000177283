import { createWebHistory, createRouter } from "vue-router";

// import App from '../App.vue'
import Home from '../components/Home.vue'
import About from '../components/About.vue'
// import CheapWT from '../components/CheapWT.vue'
import NotFound from '../components/NotFound.vue'

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: About },
  /*
  { path: '/treasuries', name: 'Treasuries', component: Treasuries},
  { path: '/stocktwits/me', name: 'Stocktwits_My', component: Stocktwits_My},
  { path: '/stocktwits/trending/today', name: 'Stocktwits_Trend_Today', component: Stocktwits_Trend_Today},
  { path: '/derivatives/top', name: 'TopMMO', component: TopMMO},
   */
  // { path: '/symbols/warrants', name: 'CheapWT', component: CheapWT},
  /*
  {
    path: '/symbol/:symbolId',
    name: 'Symbol',
    props: true,
    component: () =>
      import ('../components/Symbol.vue')
  },
  {
    path: '/symbols/warrants/:val',
    name: 'Warrants',
    props: true,
    component: () =>
      import ('../components/CheapWT.vue')
  },
  {
    path: '/scans/:which/:by',
    name: 'Scans',
    props: true,
    component: () =>
      import ('../components/ScansCS.vue')
  },
  */
  { path: '/:catchAll(.*)', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
