<template>
  <div class="about-container">
    <h1>About Ed Powers</h1>
    <div class="profile">
      <img src="/src/assets/ed_profile_pic.jpg" alt="Ed Powers" class="profile-picture">
      <div class="bio">
        <h2>Data Engineer and ML/RL Specialist</h2>
        <p>
          I'm Ed Powers, a passionate data engineer with expertise in time-series machine learning, data pipelines, and deployment in market settings. My journey in the world of data science and software engineering has been driven by a curiosity for solving complex problems and a commitment to leveraging cutting-edge technologies.
        </p>
        <p>
          With a strong foundation in Python and Go, I specialize in using data science libraries like Pandas and Numpy. My experience extends to machine learning frameworks such as PyTorch, Sktime, and Catboost for gradient boosting, as well as working with open-source NLP models from HuggingFace.
        </p>
        <h3>Professional Journey</h3>
        <p>
          My career has taken me through various roles, from wealth management at UBS Financial Services to founding an algorithmic trading company. Currently, I'm working as a Python Engineer at Kunai - Capital 1, where I focus on payment integration and agile development practices.
        </p>
        <h3>Education and Continuous Learning</h3>
        <p>
          I hold a Master's in Business Analytics and Data Science from Cass Business School in London, where I graduated with distinction. My educational background also includes a BSc in Business Administration from Babson College and a study abroad experience at Bocconi University, focusing on Venture Capital Financing and Lean Management.
        </p>
        <h3>Current Interests</h3>
        <p>
          I'm currently exploring advanced topics in machine learning, including reinforcement learning and natural language processing. I'm also passionate about open-source contributions and developing innovative solutions for financial markets.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped>
.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.profile {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.profile-picture {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

h1, h2, h3 {
  color: #333;
}

p {
  line-height: 1.6;
  color: #666;
}

@media (max-width: 768px) {
  .profile {
    flex-direction: column;
    align-items: center;
  }

  .profile-picture {
    margin-bottom: 1rem;
  }
}
</style>
